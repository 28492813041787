.paginate {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 6px;
  flex-wrap: wrap;
}

.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  color: #5f6368;
  font-size: 14px;
  font-weight: 400;
}

.page-item-prevnext {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  color: #5f6368;
  font-size: 14px;
  font-weight: 500;
}

.page-link {
  padding: 0.5rem 1rem;
}

.active {
  background-color: #93328e;
  color: #ffffff;
  border: 1px solid #93328e;
}

@media (max-width: 480px) {
  .page-item {
    font-size: 12px;
  }

  .page-link {
    padding: 0.4rem 0.8rem;
  }
}
